
/*eslint-disable*/
import React from "react";
import { Container } from "reactstrap";
import styled from 'styled-components';
import { versionsService } from '../../services';
// used for making the prop types of this component
import PropTypes from "prop-types";

const VersionText = styled.span`
  float: right;

  @media (max-width: 576px) {
    float: none;
    display: block;
    text-align: center;
  }
`;

/* {1900 + new Date().getYear()} instead of 2021 */
class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            portalVersion: null,
            serverVersion: null,
            portalVersionError: false
        };
    }

    componentDidMount() {
        // fetch version number(s)
        versionsService.getVersionNumbers()
            .then(data => {
                if (data && data.portalVersion && data.serverVersion) {
                    this.setState({
                        portalVersion: data.portalVersion,
                        serverVersion: data.serverVersion,
                        portalVersionError: false
                    });
                } else {
                    this.setState({
                        portalVersionError: true
                    });
                }
            })
            .catch(error => {
                this.setState({
                    portalVersionError: true
                });
            });
    }

    render() {
        const { portalVersion, serverVersion, portalVersionError } = this.state;

        return (
            <footer
                className={"footer" + (this.props.default ? " footer-default" : "")}
            >
                <Container fluid={this.props.fluid}>
                    <div className="copyright">
                        &copy; {1900 + new Date().getYear()}. Built by {" "}
                        <a
                            href="http://www.careloophealth.com//"
                            target="_blank"
                        >
                            Careloop Health
                        </a>
                        .
                        {(portalVersion && serverVersion && !portalVersionError) &&
                            <>
                                <br/>
                                <VersionText>Portal/Server Version: {portalVersion}/{serverVersion}</VersionText>
                            </>
                        }
                    </div>
                </Container>
            </footer>
        );
    }
}

Footer.defaultProps = {
  default: false,
  fluid: false
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
