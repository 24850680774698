import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import IdleTimer from 'react-idle-timer';

// core components
import Footer from "components/Footer/Footer.jsx";

import routes from "routes.js";

import { userService } from '../services/data.service';
import { FeatureFlagProvider } from 'contexts/FeatureFlagContext';
import { SideNavigation } from 'components/SideNavigation/SideNavigation';

var ps;

class Admin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      backgroundColor: "blue",
      timeout: 1000 * 60 * 15,
      showModal: false,
      userLoggedIn: props.loggedIn,
      isTimedOut: false,
      isMobile: window.innerWidth < 992,
      isMenuOpen: false,
    };

    this.idleTimer = null;
    this.onIdle = this.onIdle.bind(this);
  }

  handleResize = () => {
    const isMobile = window.innerWidth < 992;

    this.setState((prevState) => ({
      isMobile,
      isMenuOpen: isMobile ? prevState.isMenuOpen : false,
    }));
  };

  onIdle = e => {
    userService.logout();
    window.location.href = window.BASE_URL;
  }

  toggleMenu = () => {
    this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }));
  };

  mainPanel = React.createRef();
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.mainPanel.current);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (document.documentElement) document.documentElement.scrollTop = 0;
      if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
      if (this.mainPanel) this.mainPanel.current.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  render() {
    const { isMobile, isMenuOpen } = this.state;
    const mainContentPadding = isMobile
        ? isMenuOpen
            ? "pt-[100px]"
            : "pt-[64px]"
        : "pt-0";

    return (
      <>
        <FeatureFlagProvider>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onIdle={this.onIdle}
            debounce={500}
            timeout={this.state.timeout} />

          <div className="wrapper">
            <SideNavigation
              routes={routes}
              logout={userService.logout}
              isMobile={isMobile}
              isMenuOpen={isMenuOpen}
              toggleMenu={this.toggleMenu}
            />
            <div className={`main-panel transition-all ${mainContentPadding}`} ref={this.mainPanel}>
              <Switch>
                {
                  this.getRoutes(routes)
                }
                <Redirect from="/admin" to="/admin/overviewstats" />
              </Switch>

              <Footer fluid />

            </div>
          </div>
        </FeatureFlagProvider>
      </>
    );
  }
}

export default Admin;
