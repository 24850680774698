import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Button, NavLink } from 'reactstrap';

const Wrapper = styled.div`
  // width: calc(100% - 200px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const PatientTitle = styled.h2`
  color: #4e2056;
`;

const PatientDataList = styled.ul`
  span {
    color: #4e2056;
  }
`;

const EditButton = styled(Button)`
  margin: 0;
`;

const formatDate = (dob) => {
  if (!dob) return '--';
  const date = moment(dob);
  const formattedDate = date.format('DD-MMM-YYYY');
  const age = moment().diff(date, 'years');

  return `${formattedDate} (${age} years old)`;
};

const formatId = id => {
  if (id == null) {
    return '--';
  }
  if (id.length === 10 && /^\d{10}$/.test(id)) {
    return `${id.slice(0, 3)} ${id.slice(3, 6)} ${id.slice(6)}`;
  }
  return id;
};

export const PatientBanner = ({ id, participant }) => {
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [contactNumber, setContactNumber] = useState('');

  const {
    dob,
    user: {
      login,
      firstName,
      lastName,
      email,
      phone,
      mobile
    } = {}
  } = participant || {};

  useEffect(() => {
    setDateOfBirth(formatDate(dob));

    if (phone || mobile) {
      setContactNumber(phone || mobile);
    }
  }, [dob, phone, mobile]);

  return (
    <Wrapper className="p-8 bg-white w-100 fixed md:!w-full lg:!w-[calc(100%-200px)]">
      <PatientTitle className="font-bold text-2xl mb-[10px]">{lastName ?? '--'}, {firstName ?? '--'}</PatientTitle>

      <PatientDataList className="list-none p-0 m-0 flex-col gap-1 hidden md:!flex md:flex-row md:gap-5">
        <li><p className="text-base"><span className='font-bold mr-1'>Date of Birth:</span>{dateOfBirth}</p></li>
        <li><p className="text-base"><span className='font-bold mr-1'>ID Number:</span>{formatId(login)}</p></li>
        <li><p className="text-base"><span className='font-bold mr-1'>Email:</span>{email ?? '--'}</p></li>
        <li><p className="text-base"><span className='font-bold mr-1'>Phone:</span>{contactNumber ?? '--'}</p></li>
      </PatientDataList>

      <EditButton 
        className="w-full m-0 mt-4 lg:absolute lg:top-[15px] lg:!mt-0 lg:right-[15px] lg:w-[100px] xl:top-[30px] xl:right-[30px]"
        color="primary" 
        tag={NavLink} 
        href={`/clintouch/admin/participants/${id}/setup`}>
          Mobile Setup
      </EditButton>
    </Wrapper>
  );
};
