import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import QRCode from "react-qr-code";
import styled from 'styled-components';
import { Button } from 'reactstrap';

import { participantsActions } from '../../actions';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { PatientBanner } from 'components/PatientBanner/PatientBanner';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const InstallSection = styled.section`
  margin-bottom: 1rem;
  text-align: center;

  h3 {
    margin-bottom: 0.6rem;
    font-size: 1.2rem;
  }

  span {
    background-color: #f0f0f0;
    margin: 0.2rem; 
    border: 1px solid black;
    padding: 0.25rem 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    display: inline-block;
    text-align: center;
  }

  @media (max-width: 768px) {
    span {
      font-size: 1.75rem;
      padding: 0.25rem 0.5rem;
    }
  }
`;

const SetupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const QRCodeWrapper = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem; 
  text-align: center;

  svg {
    justify-self: center;
    width: 75% !important;
  }

  @media (max-width: 768px) {
    flex: 1;
    svg {
      width: 85% !important;
    }
  }
`;

const CodeBoxesWrapper = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem; 
  padding: 1rem;

  @media (max-width: 768px) {
    flex: 2;
  }
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem; 

  button {
    background-color: #d32f2f;
    color: white;
    padding: 0.6rem 1.25rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      background-color: #b71c1c;
    }

    &:focus {
      outline: none;
    }
  }
`;

export const ServiceUserMobileSetup = withRouter(({ history }) => {
  const { pid: participantId } = useParams();

  const [mobileCode, setMobileCode] = useState('1234567');

  const {
    summary
  } = useSelector((state) => state.participants);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(participantsActions.getSummary(participantId));
  }, [dispatch, participantId]);


  useEffect(() => {
    if (summary.participant) {
      setMobileCode(summary.participant.user.mobileCode);
    }
  }, [summary.participant]);

  const handleBack = () => {
    window.location.href = `/clintouch/admin/participants/${participantId}/summary`;
  }

  return (
    <>
      <PatientBanner id={participantId} participant={summary.participant} />
      <PageWrapper title={'Mobile setup details'} withPatientBanner>
        <SetupContainer>
          <QRCodeWrapper>
            <InstallSection>
              <h3>To start using the mobile app, scan the below QR code</h3>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={mobileCode}
                viewBox={`0 0 256 256`}
              />
            </InstallSection>
          </QRCodeWrapper>
          <CodeBoxesWrapper>
            <InstallSection>
              <h3>Or enter this mobile code manually</h3>
              {mobileCode.split('').map((char, index) => (
                  <span key={index}>{char}</span>
              ))}
            </InstallSection>
          </CodeBoxesWrapper>
        </SetupContainer>
        <BackButtonWrapper>
          <Button className="btn-fill bg-red-600" type="submit" onClick={handleBack}>
            Back
          </Button>
        </BackButtonWrapper>
      </PageWrapper>
    </>
  );
});
